import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import Form from '../forms/Form'
import {getBooleanFormatter, getNumberFormatter} from "../grid/ColumnHelpers"
import {virszemesUdensObjektsEkoActions, virszemesUoEkoYearFetch} from '../../actions/virszemesUdensObjektiActions'
import FormCard from '../forms/FormCard'
import {
  Row,
} from 'reactstrap'
import {getTableRenderer, mdToGridColumns} from "./common";
import {getKlasifikatorsFieldCfg, getKodifRenderer} from "../forms/FormHelpers";
import NextPrevBox from "../forms/NextPrevBox";

const getFormFieldWrapperCfgs = () => {
  return [
    {
      id: 'kvalitate_wrap',
      comp: FormCard,
      compProps: {
        title: 'Kvalitātes klase',
        pinnable: false,
        size: 12
      },
    },
    {
      id: 'raditaji_wrap',
      comp: FormCard,
      compProps: {
        title: 'Fizikāli ķīmiskie parametri',
        pinnable: true,
        size: 12
      },
    },
    {
      id: 'biologiskie_wrap',
      comp: FormCard,
      compProps: {
        title: 'Bioloģiskie kvalitātes elementi',
        pinnable: true,
        // size: 12
      },
    },
    {
      id: 'hidro_wrap',
      comp: FormCard,
      compProps: {
        title: 'Hidromorfoloģiskie kvalitātes elementi',
        pinnable: true,
        // size: 12
      },
    },
    {
      id: 'visi_wrap',
      comp: FormCard,
      compProps: {
        title: 'Kvalitātes novērtējumā izmantotie dati',
        pinnable: true,
        size: 12
      },
    },
  ]
}

const getFormFieldCfgs = ({ metadata, kodif }) => () => {

  const getHeaderFormatter = (level) => {
    return (column) => {
      return <><i className={`fa fa-circle kvalitate-${level}-fg`}></i> { column.text }</>
    }

  }

  const fizKimCols = [
    {
      name: 'apzimejums',
    },
    {
      name: 'nosaukums',
    },
    {
      name: 'mervieniba',
    },
    {
      name: 'vertiba',
      formatter: (val, row) => {
        return getNumberFormatter(row.precizitate)(val)
      },
      classes: (cell, row) => {
        if (row.kvalitate) {
          return `kvalitate-${row.kvalitate}`
        }
        return ''
      }
    },
    {
      name: 'kval_augsta',
      headerFormatter: getHeaderFormatter(1)
    },
    {
      name: 'kval_laba',
      headerFormatter: getHeaderFormatter(2)
    },
    {
      name: 'kval_videja',
      headerFormatter: getHeaderFormatter(3)
    },
    {
      name: 'kval_slikta',
      headerFormatter: getHeaderFormatter(4)
    },
    {
      name: 'kval_loti_slikta',
      headerFormatter: getHeaderFormatter(5)
    },
  ]

  const visiDatiCols = [
    {
      name: 'parauga_numurs',
    },
    {
      name: 'apzimejums',
    },
    {
      name: 'nosaukums',
    },
    {
      name: 'nosaukums_aguns',
    },
    {
      name: 'vertiba',
      formatter: (val, row) => {
        return getNumberFormatter(row.precizitate)(val)
      }
    },
    {
      name: 'mervieniba',
    },
    {
      name: 'karodzini',
    },
    {
      name: 'ir_ql',
      formatter: getBooleanFormatter(false)
    },
    {
      name: 'metodika',
    },
  ]

  const getField = (field, label, wrapper) => {
    return {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: field,
      label: label,
      fieldWrapper: wrapper,
      renderer: getKodifRenderer(kodif.uoKvalitate)
    }
  }

  const getBiologiskieField = (field, label) => {
    return {
      ...getField(field, label, 'biologiskie_wrap'),
      renderer: (value) => {
        const v = Number(value)
        if (kodif.uoKvalitate[v]) {
          return kodif.uoKvalitate[v].nos
        } else if (kodif.uoKvalitate[value]) {
          return kodif.uoKvalitate[value].nos
        } else {
          return value
        }
      }
    }
  }

  const getHidroField = (field, label) => {
    return getField(field, label, 'hidro_wrap')
  }

  return [
    {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: 'kvalitate.ekologiska',
      label: 'Kopējā ekoloģiskā kvalitāte',
      fieldWrapper: 'kvalitate_wrap',
      renderer: getKodifRenderer(kodif.uoKvalitate)
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: 'kvalitate.ekologiska_apr',
      editable: false,
      label: 'Kopējā ekoloģiskā kvalitāte (aprēķinātā)',
      fieldWrapper: 'kvalitate_wrap',
      renderer: getKodifRenderer(kodif.uoKvalitate)
    },
    {
      name: 'raditaji',
      label: undefined,
      sizeForm: 12,
      fieldWrapper: 'raditaji_wrap',
      editable: false,
      renderer: getTableRenderer(
        mdToGridColumns('virszemes_udens_objekts_kvalitate_ekologija_raditaji', fizKimCols, metadata)
      )
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: 'kvalitate.ekologiska_fiz_kim',
      label: 'Kvalitāte pēc fizikāli ķīmiskiem parametriem',
      fieldWrapper: 'raditaji_wrap',
      renderer: getKodifRenderer(kodif.uoKvalitate)
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: 'kvalitate.ekologiska_fiz_kim_apr',
      editable: false,
      label: 'Kvalitāte pēc fizikāli ķīmiskiem parametriem (aprēķinātā)',
      fieldWrapper: 'raditaji_wrap',
      renderer: getKodifRenderer(kodif.uoKvalitate)
    },
    {
      ...getBiologiskieField('biologiskie.zoobentoss', 'Zoobentoss'),
    },
    {
      ...getBiologiskieField('biologiskie.fitoplanktons', 'Fitoplanktons'),
    },
    {
      ...getBiologiskieField('biologiskie.makrofiti', 'Makrofīti'),
    },
    {
      ...getBiologiskieField('biologiskie.fitobentoss', 'Fitobentoss'),
    },
    {
      ...getBiologiskieField('biologiskie.zivis', 'Zivis'),
    },
    {
      ...getBiologiskieField('biologiskie.kopvertejums', 'Bioloģijas kopvērtējums'),
    },
    {
      name: 'biologiskie.piezimes',
      label: 'Piezīmes',
      type: 'textarea',
      fieldWrapper: 'biologiskie_wrap',
    },
    {
      ...getHidroField('hidro.morf_parveidojumi', 'Morfoloģiskie pārveidojumi')
    },
    {
      ...getHidroField('hidro.hidro_rezima_izmainas', 'Hidroloģiskā režīma izmaiņas')
    },
    {
      name: 'hidro.udens_apmainas_koef',
      label: 'Ūdens apmaiņas koeficients',
      fieldWrapper: 'hidro_wrap',
      hiddenFunc: ({currentValues}) => currentValues.veids === 'ezers'
    },
    {
      ...getHidroField('hidro.nepartrauktiba', 'Nepārtrauktība'),
      hiddenFunc: ({currentValues}) => currentValues.veids === 'upe'
    },
    {
      ...getHidroField('hidro.kopvertejums', 'Hidromorfoloģijas kopvērtējums')
    },
    {
      name: 'hidro.piezimes',
      label: 'Piezīmes',
      type: 'textarea',
      fieldWrapper: 'hidro_wrap',
    },
    {
      name: 'visi_dati',
      label: undefined,
      sizeForm: 12,
      fieldWrapper: 'visi_wrap',
      editable: false,
      renderer: getTableRenderer(
        mdToGridColumns('virszemes_udens_objekts_kvalitate_ekologija_visi_dati', visiDatiCols, metadata)
      )
    },
  ]
}

class EkologijaComp extends Component {

  render() {

    const {virszemesUdensObjektsEko, user, getFormFieldCfgsFunc, kodif,
      metadata, getFormFieldWrapperCfgsFunc} = this.props

    const {selectedItem, editable, submiting} = virszemesUdensObjektsEko

    // loading while we have no virszemesUdensObjekts
    if (!selectedItem) {
      return (<Loading/>)
    }

    const onNextPrevYear = (gads) => {
      console.log('onNextPrevYear', gads)
      this.props.yearFetch({ kods: selectedItem.kods, gads })
    }

    // we cache form field cfgs
    // reload form fields if virszemesUdensObjekts changes
    if(!this.formFieldCfgs || virszemesUdensObjektsEko.id !== this.prevUoId){
      const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : getFormFieldCfgs({ metadata, kodif })
      this.formFieldCfgs = f({
        kodif,
        user
      })
      this.prevUoId = selectedItem.id
    }

    if(!this.formFieldWrapperCfgs){
      const f = getFormFieldWrapperCfgsFunc ? getFormFieldWrapperCfgsFunc : getFormFieldWrapperCfgs
      this.formFieldWrapperCfgs = f()
    }

    return <div className="form-container">
      <NextPrevBox currentValue={selectedItem.gads} onChange={onNextPrevYear}></NextPrevBox>
      <Form
        editable={editable}
        submiting={submiting}
        dataObj={selectedItem}
        formFieldCfgs={this.formFieldCfgs}
        formFieldWrapperCfgs={this.formFieldWrapperCfgs}
        onSubmit={this.props.saveAct}
        onReset={this.props.stopEditAct}
        onStartEdit={this.props.startEditAct}
        formWrapperComp={Row}
        metadataFormCfg="virszemes_udens_objekts_kvalitate_ekologija"
        tiesibasLabot="BAIS_VU_KVAL_LABOT"
      />
    </div>
  }
}

const mapStateToProps = ({virszemesUdensObjektsEko, kodif, metadata, user}) => ({virszemesUdensObjektsEko, kodif, metadata, user})
const VirszemesEkologija =  connect(mapStateToProps, { yearFetch: virszemesUoEkoYearFetch, ...virszemesUdensObjektsEkoActions })(EkologijaComp)
export default VirszemesEkologija

