import React, {Component} from 'react';
import {connect} from 'react-redux'
import { Button, Form as RSForm, Label, Input, FormGroup, Col} from 'reactstrap';
import {guiSet} from '../../actions/uiActions'

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import zipcelx from 'zipcelx'

const numberValidator = (newValue) => {
    if(isNaN(parseFloat(newValue)) || !isFinite(newValue)) {
        return {
            valid: false,
            message: 'Koordināte jāievada kā skaitliska vērtība'
        }
    }
    return true
}

const startsFormatter = (cellContent, row) => cellContent === true ? 'Jā' : ''

const parseExcelNr = (str) => parseFloat(str.replace(",","."))
const booleanTrueValues = ['Jā', 'Y', 'true', '1']
const parseExcelBoolean = (str) => str && booleanTrueValues.indexOf(str) > -1 ? true : false

const pointGeometryColumns = [{
  dataField: 'id',
  text: 'Nr.',
  editable: false,
  headerStyle: {width: 50},
  attrs: (cell) => ({id: `rowid_${cell}`})
  }, {
  dataField: 'x',
  text: 'X - garums',
  validator: numberValidator
  }, {
  dataField: 'y',
  text: 'Y - platums',
  validator: numberValidator
}]

const columns = [...pointGeometryColumns, {
      dataField: 'startsPoly',
      text: 'Sākas jauns daudzstūris',
      editor: {
        type: Type.CHECKBOX,
        value: 'true:false'
      },
      formatter: startsFormatter,
    }, {
      dataField: 'startsHole',
      text: 'Sākas caurums',
      editor: {
        type: Type.CHECKBOX,
        value: 'true:false'
      },
      formatter: startsFormatter,
}]

class KoordTable extends Component {

      constructor() {
        super();
        this.updatingSelected = false
        this.state = {
            excelCopyInData: '',
            swapXY: false
        }
      }

      //scroll into view coordinate when selected outside component (for example on map)
      componentDidUpdate(prevProps){
          const {selectedKoord} = this.props
          if(!prevProps.selectedKoord || selectedKoord !== prevProps.selectedKoord){
              //if table was updating selected then we do nothing
              if(this.updatingSelected){
                  this.updatingSelected = false
              } else {
                  const el = document.getElementById('rowid_' + selectedKoord)
                  if(el) {
                      el.scrollIntoView();
                  }
              }
          }
      }

      handleChangeSwapXY = () => {
        this.rereadExcelData(this.state.excelCopyInData, !this.state.swapXY)
        this.setState({swapXY: !this.state.swapXY})
      }

      handleOnSelect = (row, isSelect) => {
        if (isSelect && this.props.koordSelectAct) {
            this.updatingSelected = true
            this.props.koordSelectAct(row.id)
        }
      }

      onEditKoord = (oldValue, newValue, row, column) => {
        if(oldValue !== newValue){
          const df = column.dataField
          const newValueParsed =
            df === 'x' || df === 'y' ? parseFloat(newValue) :
            df === 'startsPoly' || df === 'startsHole' ? newValue === 'true' : newValue
          const newKoord = {...row}
          newKoord[df] = newValueParsed
          this.props.koordChangeAct(newKoord)
        }
      }

      onExcelDataChange = (e) => {
        this.setState({excelCopyInData: e.target.value})
        this.rereadExcelData(e.target.value, this.state.swapXY)
      }

      rereadExcelData = (excelData, swapXY) => {
        //parse data and call action to change data
        if(excelData){
          const rows = excelData.split('\n')
          const firstRow = rows[0].split('\t')
          if(firstRow.length > 1){
            const mapping = firstRow.length === 2 ? ['x', 'y'] :
                            firstRow.length === 3 ? [null, 'x', 'y'] :
                            firstRow.length === 4 ? ['x', 'y', 'startsPoly', 'startsHole'] :
                            firstRow.length >= 5 ? [null, 'x', 'y', 'startsPoly', 'startsHole'] : null
            const koords = rows.filter(r => r).map(r => {
              const cols = r.split('\t')
              const koord = {}
              mapping.forEach((m, i) => {if(m) koord[m] = cols[i]})
              return koord
            })

            //do we need to skip first row
            if(isNaN(parseExcelNr(koords[0].x)) || isNaN(parseExcelNr(koords[0].y))){
              koords.shift()
            }

            const parsedKoords = koords.map((k,i) => {
              try {
                return {
                  id: i+1,
                  x: parseExcelNr(swapXY ? k.y : k.x),
                  y: parseExcelNr(swapXY ? k.x : k.y),
                  startsPoly: parseExcelBoolean(k.startsPoly),
                  startsHole: parseExcelBoolean(k.startsHole),
                }
              } catch(err) {
                this.props.showErrorMsg(`Kļūda, lasot Excel datus (${i+1}. rinda): ${err.message}`)
                throw err;
              }
            })
            if(this.props.isPointGeometry){
              this.props.koordChangeAct([parsedKoords[0]])
            } else {
              this.props.koordChangeAct(parsedKoords)
            }
          }
        }
      }

      downloadCoordinates = () => {
        const cells = this.props.koords.map(k=>[
          {value: k.id, type: 'number'},
          {value: k.x, type: 'number'},
          {value: k.y, type: 'number'},
          {value: k.startsPoly ? 'Y':'',  type: 'string'},
          {value: k.startsHole ? 'Y':'',  type: 'string'},
        ])
        const excelConfig = {
          filename: 'koordinates',
          sheet: {
            data: cells
          }
        }
        zipcelx(excelConfig)
      }

      deleteCoords = () => {
          this.props.koordChangeAct([])
      }

      render() {
        const {gui, editable, koords, selectedKoord, validation, alwaysShow, geomTitle, isPointGeometry, showAdvancedFields, simpleColumns} = this.props

        if(!alwaysShow && !gui.showCoordinateTable) {
            return null
        }

        return      <>
                    { geomTitle ? <span className="id_renderer">{geomTitle}</span> : null}
                    { editable && (showAdvancedFields !== false) ?
                      <RSForm>
                        <FormGroup row>
                          <Col xs={12} md={4}>
                            <Label >Excel datu iekopēšana</Label>
                          </Col>
                          <Col xs={12} md={8}>
                            <Input type="textarea" value={this.state.excelCopyInData} onChange={this.onExcelDataChange} placeholder="lai aizstātu koordināšu tabulas datus un ģeometriju, iekopējiet no izklājlapas divas kolonas ar x un y vērtībām" />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Col xs={12} md={4}>
                          </Col>
                          <Col xs={12} md={8}>
                            <FormGroup check inline>
                              <Label check>
                                  <Input type="checkbox" value={this.state.swapXY} onChange={this.handleChangeSwapXY} checked={this.state.swapXY}/> mainīt x un y vietām kopējot no izklājlapas
                              </Label>
                            </FormGroup>
                          </Col>
                        </FormGroup>

                      </RSForm>
                      : null }

                      <div className="danger">{validation}</div>

                      <div>
                        <div className="coord-grid-top">
                      <div className="editable-grid-info">{ editable && (showAdvancedFields !== false) ? <><i className="fa fa-pencil"></i>&nbsp;&nbsp;Lai rediģētu tabulas vērtības, jāveic peles dubultklikšķis uz attiecīgās šūnas tabulā.</> : '' } </div>
                        <div className="buttons">
                        { editable && (showAdvancedFields !== false) ?
                          <Button color="danger"
                          onClick={this.deleteCoords}>
                          <i className="fa fa-trash-o"></i>&nbsp;&nbsp;Dzēst koordinātes</Button>
                        : null}
                        <Button color='secondary' onClick={this.downloadCoordinates}>
                        <i className="fa fa-download"></i>&nbsp;&nbsp;Lejupielādēt koordinātes</Button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <BootstrapTable
                          bootstrap4
                          striped
                          hover
                          condensed
                          keyField="id"
                          data={ koords }
                          columns={ isPointGeometry || simpleColumns ? pointGeometryColumns : columns }
                          cellEdit={ cellEditFactory({
                              mode: 'dbclick',
                              blurToSave: true ,
                              beforeSaveCell: this.onEditKoord,
                              nonEditableRows: () => editable ? [] : koords.map(k => k.id)
                          })}
                          selectRow={{
                              mode: 'radio',
                              clickToSelect: true,
                              clickToEdit: true,
                              hideSelectColumn: true,
                              bgColor: 'rgba(49, 102, 156, 0.3)',
                              onSelect: this.handleOnSelect,
                              selected: [selectedKoord]
                          }}
                      />
                    </div>

                    </>
    }
}

const mapStateToProps = ({gui}) => ({gui})
export default connect(mapStateToProps)(KoordTable)

class ShowKoordTableBtnComp extends Component {

    toggleShowCoordTable = () => {
        this.props.guiSet('showCoordinateTable', !this.props.gui.showCoordinateTable);
    }

    render() {
        const showCoordinateTable = this.props.gui.showCoordinateTable
        return  <Button color={showCoordinateTable ? 'primary' : 'secondary'}
                    className="float-right" onClick={this.toggleShowCoordTable}>
                <i className="fa fa-map-marker"></i>&nbsp;&nbsp;{showCoordinateTable ? 'Slēpt' : 'Rādīt'} koordināšu tabulu
            </Button>
    }
}
const mapStateToPropsBtn = ({gui}) => ({gui})
export const ShowKoordTableBtn = connect(mapStateToPropsBtn, {guiSet})(ShowKoordTableBtnComp)
