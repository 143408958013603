import React, {Component} from 'react'
import {connect} from 'react-redux'
import Loading from '../Loading'
import { uoPazemesPasakumiActions } from '../../actions/pazemesUdensObjektiActions'
import { pasakumsPazemesDoubleSelected } from '../../actions/pasakumiActions'
import BootstrapTable from 'react-bootstrap-table-next'
import {columnCfgToBootstrapTableColumn} from '../grid/Grid'
import {getEditIconColumn} from "../grid/ColumnHelpers";
import {Button} from "reactstrap";
import Link from "redux-first-router-link";

const editIconColumn = getEditIconColumn((row) => (
    {
      type: 'loc_pazemes_pasakums',
      payload: {
        id: row.id,
        kods: row.uo_kods
      }
    }
  )
)

const getColumns = () => {
  const columns = [
    editIconColumn,
    {
      dataField: 'kods',
      text: 'Kods',
      width: 50
    },
    {
      dataField: 'nosaukums',
      text: 'Nosaukums',
      width: 400
    },
    {
      dataField: 'apraksts',
      text: 'Apraksts',
      width: 200,
    },
    {
      dataField: 'izpilde',
      text: 'Izpilde',
      width: 250
    }
]
  return columns.map(col => columnCfgToBootstrapTableColumn(col))
}

class PazemesPasakumiComp extends Component {

  constructor(props) {
    super(props)
    this.state = {
      licence: null,
      limits: null
    }
  }

  rowEvents = {
    onDoubleClick: (row) => {
      pasakumsPazemesDoubleSelected({
        id: row.id,
        uo_kods: row.uo_kods
      })
    }
  }

  render() {

    const {selectedItem} = this.props.uoPazemesPasakumi

    if (!selectedItem) {
      return (<Loading/>)
    }

    const {kodif} = this.props
    const {kods} = selectedItem
    if (!this.pasakumiCols) {
      this.pasakumiCols = getColumns({kodif, kods, uoPazemesPasakumi: selectedItem})
    }

    return <div className="full-height-form-or-grid-container">
        { this.props.user.tiesibas.includes('BAIS_PU_LABOT') ? (
          <div className="actionButtons">
            <Link to={{ type: 'loc_pazemes_pasakums',
              payload: {
                id: 'jauns',
                kods: selectedItem.kods
              }
            }}>
              <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauns pasākums</Button>
            </Link>
          </div>
        ) : null }
      <BootstrapTable
        bootstrap4
        striped
        hover
        condensed
        keyField="id"
        data={selectedItem.pasakumi}
        columns={this.pasakumiCols}
        rowEvents={this.rowEvents}
      />
    </div>
  }
}

const mapStateToProps = ({uoPazemesPasakumi, kodif, user}) => ({
  uoPazemesPasakumi,
  kodif,
  user
})
const PazemesPasakumi = connect(mapStateToProps, {...uoPazemesPasakumiActions})(PazemesPasakumiComp)
export default PazemesPasakumi