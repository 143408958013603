import React, {Component} from 'react'
import {connect} from 'react-redux'
import Loading from '../Loading'
import {uoPasakumiActions} from '../../actions/virszemesUdensObjektiActions'
import { pasakumsVirszemesDoubleSelected } from '../../actions/pasakumiActions'
import BootstrapTable from 'react-bootstrap-table-next'
import {columnCfgToBootstrapTableColumn} from '../grid/Grid'
import {getEditIconColumn, getFormatterForKodif, getSelectFilterCfg} from "../grid/ColumnHelpers";
import {Button} from "reactstrap";
import Link from "redux-first-router-link";

const editIconColumn = getEditIconColumn((row) => (
    {
      type: 'loc_virszemes_pasakums',
      payload: {
        id: row.id,
        kods: row.uo_kods
      }
    }
  )
)

const getColumns = (kodif) => {
  const columns = [
    editIconColumn,
    {
      dataField: 'kods',
      text: 'Kods',
      width: 50
    },
    {
      dataField: 'nosaukums',
      text: 'Nosaukums',
      width: 410
    },
    {
      dataField: 'slodzes_veids',
      text: 'Ietekmētā slodze',
      filterCfg: getSelectFilterCfg(kodif.pasakumiSlodze),
      width: 300,
      formatter: getFormatterForKodif(kodif.pasakumiSlodze)
    },
    {
      dataField: 'izpilde',
      text: 'Izpilde',
      width: 250
    }
  ]
  return columns.map(col => columnCfgToBootstrapTableColumn(col))
}

class PasakumiComp extends Component {

  constructor(props) {
    super(props)
    this.state = {
      licence: null,
      limits: null
    }
  }

  rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      pasakumsVirszemesDoubleSelected({
        id: row.id,
        uo_kods: row.uo_kods
      })
    }
  }

  render() {

    const {selectedItem} = this.props.uoPasakumi

    // loading while we have no record
    if (!selectedItem) {
      return (<Loading/>)
    }

    const {kodif} = this.props
    if (!this.pasakumiCols) {
      this.pasakumiCols = getColumns(kodif)
    }

    return <div className="full-height-form-or-grid-container">
        { this.props.user.tiesibas.includes('BAIS_VU_PAMAT_LABOT') ? (
          <div className="actionButtons">
            <Link to={{ type: 'loc_virszemes_pasakums',
              payload: {
                id: 'jauns',
                kods: selectedItem.kods
              }
            }}>
              <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauns pasākums</Button>
            </Link>
          </div>
        ) : null }
      <BootstrapTable
        bootstrap4
        striped
        hover
        condensed
        keyField="id"
        data={selectedItem.pasakumi}
        columns={this.pasakumiCols}
        rowEvents={this.rowEvents}
      />
    </div>
  }
}

const mapStateToProps = ({uoPasakumi, kodif, user}) => ({
  uoPasakumi,
  kodif,
  user
})
const VirszemesPasakumi = connect(mapStateToProps, {...uoPasakumiActions})(PasakumiComp)
export default VirszemesPasakumi
